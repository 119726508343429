import React from "react";
import Table from "react-bootstrap/Table";

const DDMSExample = () => {
  return (
    <>
      <h4 className="font-weight-bold mb-2">Example Format</h4>
      <div className="overflow-auto mb-3">
        <Table className="uploadExampleTable table-mb">
          <thead>
            <tr>
              <th>District_Name</th>
              <th>Last</th>
              <th>First</th>
              <th>Student_ID</th>
              <th>Race</th>
              <th>Gender</th>
              <th>Free or Reduced Lunch</th>
              <th>Special Education</th>
              <th>ELL</th>
              <th>SLD</th>
              <th>Additional_Codes</th>
              <th>DIBELS_Approved_Accommodations</th>
              <th>Year_K</th>
              <th>School_K</th>
              <th>Class_K</th>
              <th>Teacher_K</th>
              <th>LNF_K_Middle</th>
              <th>Benchmark_Status_LNF_K_Middle</th>
              <th>PSF_K_Middle</th>
              <th>Benchmark_Status_PSF_K_Middle</th>
              <th>NWF-CLS_K_Middle</th>
              <th>Benchmark_Status_NWF-CLS_K_Middle</th>
              <th>NWF-WRC_K_Middle</th>
              <th>Benchmark_Status_NWF-WRC_K_Middle</th>
              <th>WRF_K_Middle</th>
              <th>Benchmark_Status_WRF_K_Middle</th>
              <th>Composite_K_Middle</th>
              <th>Benchmark_Status_Composite_K_Middle</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sample District</td>
              <td>Doe</td>
              <td>John</td>
              <td>1111</td>
              <td>Asian/White</td>
              <td>Male</td>
              <td>N</td>
              <td>Y</td>
              <td>N</td>
              <td>N</td>
              <td></td>
              <td></td>
              <td>2022</td>
              <td>Sample School</td>
              <td>Sample Class</td>
              <td>Sample Teacher</td>
              <td>45</td>
              <td>Intensive Support</td>
              <td>48</td>
              <td>Core Support</td>
              <td>65</td>
              <td>Core Support</td>
              <td>19</td>
              <td>Core Support</td>
              <td>45</td>
              <td>Core Support</td>
              <td>66</td>
              <td>Core Support</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <p className="mb-1">In addition to matching your file with the example format, please ensure your file does <b><u>not</u></b> contain:</p>
      <ul>
        <li>Duplicate student IDs</li>
        <li>Blank student IDs</li>
        <li>Blank student names</li>
        <li>More than one sheet in a workbook</li>  
      </ul>  
    </>
  );
};
export default DDMSExample;
