import React, { useEffect } from "react";
import { Cell } from "fixed-data-table-2";
import { Dropdown } from "react-bootstrap";
import {
  EvidenceColumn,
  EvidenceColumnGroup,
  EvidenceType,
} from "../../../../../../../store/onboarding/types";
import FontAwesomeIconDropDown from "../drop-down/FontAwesomeIconDropDown";

import { Portal } from "react-portal";
import { evidenceColumnDisplayTitle } from "../Helpers";
import MeasurementLabelTooltip from "../MeasurementLabelTooltip";
import { useDispatch, useSelector } from "react-redux";

type Props = {
  sortingDirection?: "asc" | "desc" | undefined;
  evidenceColumn: EvidenceColumn;
  evidenceColumnGroup: EvidenceColumnGroup;
  onBenchmarksView: (
    assessmentName: string,
    selectedEvidenceColumnGroupId: number,
    selectedEvidenceColumn: EvidenceColumn,
    isBehaviorPrimary: boolean,
  ) => any;
  onEvidenceSort: any;
  // onEvidenceSort: (
  //   evidence_column: EvidenceColumn,
  //   evidenceColumnGroupId: number
  // ) => any;
};

const MeasurementHeaderCell = (props: Props) => {

  const {
    evidenceColumn,
    evidenceColumnGroup,
    onBenchmarksView,
    onEvidenceSort,
    sortingDirection,
  } = props;


  return (
    <Cell className="testLabel">
      <div className="testLabelDropdown">
        <Dropdown>
          <Dropdown.Toggle
            as={FontAwesomeIconDropDown(
              <div className="testLabelName">
                <h5
                  className={
                    evidenceColumn.evidence_type === EvidenceType.NeedAreas ||
                      evidenceColumn.evidence_type === EvidenceType.DiagnosticInfo || 
                      evidenceColumn.evidence_type === EvidenceType.DiagnosticFollowUp
                      ? "contentAreasHeader"
                      : "contentAreasStandardHeader"
                  }
                >
                  <MeasurementLabelTooltip
                    evidenceColumn={evidenceColumn}
                    sortingDirection={sortingDirection}
                  />
                </h5>
              </div>
            )}
            id={"dropdownToggleEvidence"}
          />
          {evidenceColumn.evidence_type !== EvidenceType.BehaviorScreener && (<Portal>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={onEvidenceSort(evidenceColumn, evidenceColumnGroup)}
              >
                Sort by <b>{evidenceColumnDisplayTitle(evidenceColumn)}</b>
              </Dropdown.Item>
              {evidenceColumn.evidence_type === EvidenceType.Data && (
                <Dropdown.Item
                  onClick={onBenchmarksView(
                    evidenceColumnGroup.assessment.name,
                    evidenceColumnGroup.id,
                    evidenceColumn,
                    evidenceColumnGroup.is_behaviour_primary_screening
                  )}
                >
                  View Benchmarks
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Portal>)}
        </Dropdown>
      </div>
    </Cell>
  );
};

export default MeasurementHeaderCell;
