import React from "react";
import Table from "react-bootstrap/Table";

const MClassExample = () => {
  return (
    <>
      <h4 className="font-weight-bold mb-2">Example Format</h4>
      <div className="overflow-auto mb-3">
        <Table className="uploadExampleTable table-mb">
          <thead>
            <tr>
              <th>StudentSummary</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th>School Year</th>
              {/* <th>State</th>
              <th>Account Name</th>
              <th>Municipality Name</th>
              <th>Municipality Primary ID</th> */}
              <th>District Name</th>
              {/* <th>District Primary ID</th>
              <th>Internal Program</th>
              <th>External Program</th> */}
              <th>School Name</th>
              {/* <th>Primary School ID</th>
              <th>Secondary School ID</th> */}
              <th>Student Last Name</th>
              <th>Student First Name</th>
              {/* <th>Student Middle Name</th>
              <th>Enrollment Date</th> */}
              <th>Enrollment Grade</th>
              {/* <th>Date of Birth</th> */}
              <th>Gender</th>
              <th>Race</th>
              <th>Special Education</th>
              {/* <th>Disability</th> */}
              <th>SLD</th>
              {/* <th>Section 504</th>
              <th>Economically Disadvantaged</th> */}
              <th>Free or Reduced Lunch</th>
              {/* <th>Title 1</th>
              <th>Migrant</th>
              <th>English Proficiency</th> */}
              <th>ELL</th>
              {/* <th>Home Language</th>
              <th>Alternate Assessment</th>
              <th>Approved Accommodations</th>
              <th>Classed</th>
              <th>Reporting Class Name</th>
              <th>Reporting Class ID</th> */}
              <th>Official Teacher Name</th>
              {/* <th>Official Teacher Staff ID</th>
              <th>Assessing Teacher Name</th>
              <th>Assessing Teacher Staff ID</th> */}
              <th>Assessment</th>
              <th>Assessment Edition</th>
              {/* <th>Assessment Grade</th> */}
              <th>Benchmark Period</th>
              {/* <th>Composite Level</th> */}
              <th>Composite Score</th>
              {/* <th>Composite - National Norm Percentile</th>
              <th>Composite - Semester Growth</th>
              <th>Composite - Year Growth</th> */}
              {/* <th>Letter Names (LNF) - Level</th> */}
              <th>Letter Names (LNF) - Score</th>
              {/* <th>Letter Names (LNF) - National Norm Percentile</th>
              <th>Letter Names (LNF) - Semester Growth</th>
              <th>Letter Names (LNF) - Year Growth</th> */}
              {/* <th>Phonemic Awareness (PSF) - Level</th> */}
              <th>Phonemic Awareness (PSF) - Score</th>
              {/* <th>Phonemic Awareness (PSF) - National Norm Percentile</th>
              <th>Phonemic Awareness (PSF) - Semester Growth</th>
              <th>Phonemic Awareness (PSF) - Year Growth</th> */}
              {/* <th>Letter Sounds (NWF-CLS) - Level</th> */}
              <th>Letter Sounds (NWF-CLS) - Score</th>
              {/* <th>Letter Sounds (NWF-CLS) - National Norm Percentile</th>
              <th>Letter Sounds (NWF-CLS) - Semester Growth</th>
              <th>Letter Sounds (NWF-CLS) - Year Growth</th> */}
              {/* <th>Decoding (NWF-WRC) - Level</th> */}
              <th>Decoding (NWF-WRC) - Score</th>
              {/* <th>Decoding (NWF-WRC) - National Norm Percentile</th>
              <th>Decoding (NWF-WRC) - Semester Growth</th>
              <th>Decoding (NWF-WRC) - Year Growth</th> */}
              {/* <th>Word Reading (WRF) - Level</th> */}
              <th>Word Reading (WRF) - Score</th>
              {/* <th>Word Reading (WRF) - National Norm Percentile</th>
              <th>Word Reading (WRF) - Semester Growth</th>
              <th>Word Reading (WRF) - Year Growth</th> */}
              {/* <th>Reading Accuracy (ORF-Accu) - Level</th> */}
              <th>Reading Accuracy (ORF-Accu) - Score</th>
              {/* <th>Reading Accuracy (ORF-Accu) - National Norm Percentile</th>
              <th>Reading Accuracy (ORF-Accu) - Semester Growth</th>
              <th>Reading Accuracy (ORF-Accu) - Year Growth</th> */}
              {/* <th>Reading Fluency (ORF) - Level</th> */}
              <th>Reading Fluency (ORF) - Score</th>
              {/* <th>Reading Fluency (ORF) - National Norm Percentile</th>
              <th>Reading Fluency (ORF) - Semester Growth</th>
              <th>Reading Fluency (ORF) - Year Growth</th> */}
              <th>Error Rate (ORF) - Score</th>
              {/* <th>Reading Comprehension (Maze) - Level</th> */}
              <th>Reading Comprehension (Maze) - Score</th>
              {/* <th>Reading Comprehension (Maze) - National Norm Percentile</th>
              <th>Reading Comprehension (Maze) - Semester Growth</th>
              <th>Reading Comprehension (Maze) - Year Growth</th>
              <th>Correct Responses (Maze) - Score</th>
              <th>Incorrect Responses (Maze) - Score</th>
              <th>Client Date</th>
              <th>Sync Date</th> */}
              <th>Student Primary ID</th>
              {/* <th>Primary ID - Student ID (District ID)</th>
              <th>Administration Type</th>
              <th>Vocabulary - Level</th>
              <th>Vocabulary - Score</th>
              <th>Spelling - Level</th>
              <th>Spelling - Score</th>
              <th>RAN - Level</th>
              <th>RAN - Score</th>
              <th>Risk Indicator - Level</th>
              <th>DIBELS Composite Score - Lexile</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2023-2024</td>
              {/* <td>NA</td>
              <td>Demo School District</td>
              <td></td>
              <td>DEMODISTRICT</td> */}
              <td>Demo School District</td>
              {/* <td>DEMODISTRICT</td>
              <td></td>
              <td></td> */}
              <td>Demo School</td>
              {/* <td>School1</td>
              <td></td> */}
              <td>Student First Name</td>
              <td>Student Last Name</td>
              {/* <td></td>
              <td>01/09/2023</td> */}
              <td>1</td>
              {/* <td>20/04/2016</td> */}
              <td>M</td>
              <td>W</td>
              <td>Y</td>
              {/* <td>N</td> */}
              <td></td>
              {/* <td>N</td>
              <td>N</td> */}
              <td>Y</td>
              {/* <td>N/A</td>
              <td>N</td>
              <td>N/A</td> */}
              <td></td>
              {/* <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>Y</td>
              <td>Reading - Teacher</td>
              <td>1234</td> */}
              <td>Teacher, Demo</td>
              {/* <td>123</td>
              <td>Assessor, Demo</td>
              <td>456</td> */}
              <td>mCLASS</td>
              <td>DIBELS 8th Edition</td>
              {/* <td>2</td> */}
              <td>BOY</td>
              {/* <td>Above Benchmark</td> */}
              <td>111</td>
              {/* <td>87</td>
              <td></td>
              <td></td> 
              <td></td>*/}
              <td>111</td>
              {/* <td></td>
              <td></td>
              <td></td> 
              <td></td>*/}
              <td>111</td>
              {/* <td></td>
              <td></td>
              <td></td> 
              <td>At Benchmark</td>*/}
              <td>111</td>
              {/* <td>Tested Out</td>
              <td></td>
              <td></td> 
              <td>At Benchmark</td>*/}
              <td>111</td>
              {/* <td>Tested Out</td>
              <td></td>
              <td></td> 
              <td>At Benchmark</td>*/}
              <td>111</td>
              {/* <td>Tested Out</td>
              <td></td>
              <td></td> 
              <td>At Benchmark</td>*/}
              <td>11</td>
              {/* <td>81</td>
              <td></td>
              <td></td> 
              <td>Above Benchmark</td>*/}
              <td>111</td>
              {/* <td>90</td> 
              <td></td>
              <td></td>*/}
              <td>1</td>
              {/* <td>Above Benchmark</td> */}
              <td>11</td>
              {/* <td>91</td>
              <td></td>
              <td></td>
              <td>16</td>
              <td>4</td>
              <td>18/09/2023</td>
              <td>18/09/2023</td> */}
              <td>1111111</td>
              {/* <td>9876</td>
              <td>Offline</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>420L</td> */}
            </tr>
          </tbody>
        </Table>
      </div>
      <p className="mb-1">In addition to matching your file with the example format, please ensure your file does <b><u>not</u></b> contain:</p>
      <ul>
        <li>Duplicate student IDs</li>
        <li>Blank student IDs</li>
        <li>Blank student names</li>
        <li>More than one sheet in a workbook</li>
      </ul> 
    </>
  );
};
export default MClassExample;
