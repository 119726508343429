import React, { FunctionComponent, useState } from "react";
import PageHelperModal from "./PageHelperModal";
import { useDispatch } from "react-redux";
import { openPageHelperModal } from "../../../store/help/actions";
import useUserRole from "../../../utils/hooks/useUserRole";

type OwnProps = {};

type Props = OwnProps;

const HelpButton: FunctionComponent<Props> = (props) => {
  // const dispatch = useDispatch();

  const { isCoach, isTeacher, isSupervisor, isDistrictOwner } = useUserRole();

  // const handleShowHelp = () => {
  //   dispatch(openPageHelperModal());
  // };

  return isCoach || isTeacher || isSupervisor || isDistrictOwner ? (
    <a
      className="marigoldBtnSm mr-2 text-decoration-none"
      href="https://readycoach.zendesk.com"
      target={"_blank"}>
      Help
    </a>
  ) : null;
};

export default HelpButton;
