import {
  InviteToken,
  OnboardingStates,
  UserAssignmentRole,
} from "../store/auth/types";
import { Grade } from "../store/groups/types";
import { ConcernArea } from "../store/onboarding/cases/types";
import _ from "lodash";

const deployEnv = process.env.REACT_APP_DEPLOY_ENV;
const deployApp = process.env.REACT_APP_APPLICATION;
const isLocal = process.env.REACT_APP_LOCAL === "TRUE";

export const IS_READY_COACH = deployApp === "readycoach";

export const APP_NAME: string = IS_READY_COACH
  ? "ReadyCoach"
  : "NJTSS Early Reading";

const URLS: { [env: string]: string } = {
  local: "/api/",
  "readycoach-staging": "https://stage-api.readycoach.net/api/",
  "readycoach-newstaging": "https://newstaging-api.readycoach.net/api/",
  "interread-staging": "/api/",
  "readycoach-production": "https://api.readycoach.net/api/",
  "readycoach-pre-prod": "https://pre-prod-api.readycoach.net/api/",
  "interread-production": "https://njtss-earlyreading.com/api/",
};

const urlKey = isLocal ? "local" : `${deployApp}-${deployEnv}`;

export const HOST_URL: string = URLS[urlKey] as string;

export const API_AUTH_URL = "/auth/login";
export const API_LOGOUT = "/auth/logout";
export const API_USER_INFO = "/users/me";
export const API_USER_PROFILE = (userId: number) => `/user/${userId}/profile`;
export const API_CHANGE_PASSWORD = () => "/change_password";
export const API_RESET_PASSWORD = "/password_reset/";
export const API_CHANGE_PASSWORD_BY_TOKEN = "/password_reset/confirm/";
export const API_TOKEN_VALIDATION = (token: string, email: string) =>
  `/custom_forgot_password_auth?${token ? 'key='+token : ''}${email ? '&email='+email : ''}`;
export const API_USER_REGISTRATION = "/registration";
export const API_COACH_TRIAL_REGISTRATION = "/registration/trial/coach";
export const API_DISTRICT_TRIAL_REGISTRATION =
  "/registration/trial/district_owner";
export const API_REGISTRATION_DISTRICTS = "/registration/districts";
export const API_UPLOAD_USER_PROFILE_IMAGE = (userId: number) =>
  `/profile/image/upload/${userId}`;
export const API_DISTRICTS = "/districts";
export const API_DISTRICT_ACCESS_DATA_SETTINGS = (districtId?: number) =>
  `/district_access_data_settings` + (districtId ? `/${districtId}` : "");
export const API_USER_STATUS = (userId: number) =>
  `user/${userId}/active_status `;
export const API_ADMIN_DISTRICTS = (districtId?: number) =>
  districtId != null ? `/admin/districts/${districtId}` : "/admin/districts";
export const API_ADMIN_DISTRICT_LICENSES = (
  districtId: number,
  licenseId?: number
) =>
  licenseId != null
    ? `/admin/districts/${districtId}/licenses/${licenseId}`
    : `/admin/districts/${districtId}/licenses`;
export const API_ADMIN_USERS = (userId?: number) =>
  userId != null ? `/admin/users/${userId}` : "/admin/users";
export const API_ADMIN_RELEASE_NOTES = () => "/admin/release_notes";
export const API_ADMIN_PUBLISH_RELEASE_NOTE = (releaseNoteId?: number) =>
  releaseNoteId != null ? `/admin/release_notes/${releaseNoteId}` : "/admin/release_notes";
export const API_ADMIN_DATA_EXTRACT = () => "/admin/data_extract";
export const API_DISTRICT_SCHOOLS = (schoolId?: number) =>
  "/district/schools" + (schoolId !== undefined ? `/${schoolId}` : "");
export const API_DISTRICT_STUDENT_TEACHERS = (studentId: number) =>
  `/district/students/${studentId}/teachers`;
export const API_DISTRICT_STUDENT_CLASSES = (studentId: number = 0, data_period_id?: number) =>
  `/district/students/${studentId}/classes`+ (data_period_id ? `?data_period_id=${data_period_id}` : "");
export const API_DISTRICT_ALL_CLASSES = (data_period_id?: number, grade?:string) =>
  `/district/classes` + (data_period_id ? `?data_period_id=${data_period_id}` : "")
  + (grade ? `&grade=${grade}` : "");
export const API_MERGE_SCHOOLS = "/district/schools/merge";
export const API_DETECT_NON_EXISTING_STUDENT_IDS = (uniqueIds: Array<string>) =>
  `/students/detect?ids=${uniqueIds.join(",")}`;

export const API_STUDENTS_DEMOGRAPHIC_DATA = "/students/demographic_data";

export const API_DISTRICT_USERS = "/district/users";

export const API_TEACHERS_ROSTER = (disabled?: boolean) =>
  "/rosters/teachers/common" + (disabled ? "?disabled=true" : "");
export const API_COACHES_ROSTER = "/rosters/coaches/common";

export const API_STUDENTS_ROSTER = "/rosters/students";

export const API_PARENTS = "/parents";

export const API_GET_TUTORIAL_VIDEOS = "/tutorial_videos";
export const API_GET_RELEASE_NOTES = "/tutorial_videos";

export const API_DISTRICT_RELATIONSHIPS = (
  role: UserAssignmentRole.COACH | UserAssignmentRole.SUPERVISOR,
  userId?: number
) => `/district/relationships/${role}` + (userId ? `/${userId}` : "");

export const API_INTERVENTION_GROUP_LOGGING = (interventionGroupId: number, studentId: number) =>
  `/intervention_group/log/${interventionGroupId}${studentId ? '?student_id='+studentId : ''}`;

export const API_TEACHERS_ROSTER_UPDATE_INFO = (teacherId: number) =>
  `/rosters/teachers/${teacherId}`;
export const API_TEACHERS_ROSTER_CLASSES = (
  teacherId: number,
  classId?: number,
  data_period_id?: number
) => `/rosters/teachers/${teacherId}/classes` + (classId ? `/${classId}` : "") + (data_period_id ? `?data_period_id=${data_period_id}` : "");
export const API_TEACHERS_ROSTER_STUDENTS = (teacherId: number) =>
  `/rosters/teachers/${teacherId}/students`;
export const API_INVITE_INFO = (inviteToken: InviteToken) =>
  `/invite/?invite=${btoa(JSON.stringify(inviteToken))}`;
export const API_ACCEPT_INVITE = "/invite/accept";
export const API_SEND_INVITE_TO_TEACHERS = "/rosters/teachers/invite";
export const API_CHECK_EMAIL_EXIST = "/user/check-existing-users";
export const API_MERGE_TEACHERS = "/teachers/merge";
export const API_REMINDER = (reminderId?: number) =>
  "/reminders" + (reminderId !== undefined ? `/${reminderId}` : "");
export const API_UPDATE_USER_ONBOARDING_STEP = (onboarding: OnboardingStates) =>
  `/onboarding/${onboarding}/complete`;
export const API_DETECT_ASSESSMENT = () => `/assessment/detect `;
export const API_EVIDENCE_DATA_UPLOAD = (assessmentId?: number) =>
  "/evidence/data/upload" + (assessmentId ? `/assessment/${assessmentId}` : "");
export const API_DATA_PERIODS = (dataPeriodId?: number) =>
  "/evidence/data_periods" + (dataPeriodId ? `/${dataPeriodId}` : "");
export const API_GET_UPDATED_AREA_OF_NEED = (dataPeriodId?: number, queryStr?:string) =>
"/evidence/get-updated-needs" + (dataPeriodId ? `/${dataPeriodId}` : "") + queryStr;
export const API_EVIDENCE_COLUMN_GROUPS = (evidenceColumnGroupsId: number) =>
  `/evidence_column_groups/${evidenceColumnGroupsId}`;
export const API_GET_EVIDENCE_IN_DATE_PERIOD = (datePeriod: number) =>
  `/evidence/data_periods/${datePeriod}`;
export const API_EVIDENCE_ASSESSMENTS = (type?: string) =>
  "/evidence/assessments" + (type ? `?type=${type}` : "");
export const API_ASSESSMENT = "/evidence/assessment";
export const API_STUDENTS_TO_DATA_PERIOD = (dataPeriod: number) =>
  `/evidence/data_periods/${dataPeriod}/students`;
export const API_ASSESSMENT_TO_DATA_PERIOD = (dataPeriod: number) =>
  `/evidence/data_periods/${dataPeriod}/assessments`;
export const API_DSE_TO_DATA_PERIOD = (dataPeriod: number, dseId?: number) =>
  `/evidence/data_periods/${dataPeriod}/data_student_entry` +
  (dseId ? `/${dseId}` : "");
export const API_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD = (dataPeriod: number, dseId?: number) =>
  `/evidence/data_periods/${dataPeriod}/diagnostic_follow_up` +
  (dseId ? `/${dseId}` : "");
export const  API_DELETE_DIAGNOSTIC_FOLLOWUP_TO_DATA_PERIOD = (studentEntryId: number) =>
  "/diagnostic_follow_up" +
  (studentEntryId !== undefined ? `/${studentEntryId}` : "");
export const API_DATA_STUDENT_ENTRY = (studentEntryId: number) =>
  "/data_student_entry" +
  (studentEntryId !== undefined ? `/${studentEntryId}` : "");
export const API_MEASUREMENTS_CUSTOM_COLOR_CRITERIA = (measurementId: number) =>
  `measurements/${measurementId}/color_criteria/custom`;
export const API_INTERVENTIONS = (concern_type?: string, id?: number) =>
  "/interventions" +
  (id ? `/${id}` : concern_type ? `?concern_type=${concern_type}` : "");
export const API_INTERVENTIONS_SCHOOL_RESOURCES = () =>
  `/interventions/school_resources`;
export const API_INTERVENTION_GROUPS = (interventionGroupId?: number) =>
  "/intervention_groups" +
  (interventionGroupId !== undefined ? `/${interventionGroupId}` : "");
export const API_INTERVENTION_GROUPS_PROGRESS_OVERVIEW = () => 
  "/all_intervention_groups/all_progress_overview";
export const API_STATIC_DATA_MEASUREMENT = "/static_data/measurement";
export const API_STATIC_DATA_ASSESSMENT = "/static_data/assessment";
export const API_STATIC_DATA_CATEGORY_DOMAINS = "/static_data/category_domains";
export const API_STATIC_DEFAULT_PROGRESS_MONITORING =
  "/static_data/default_progress_monitoring ";
export const API_STATIC_DATA_PRAISES = "/static_data/praises";
export const API_CATEGORY_BEHAVIOR = "/category/behavior";
export const API_CUSTOM_BEHAVIORS = (behaviorId?: number) =>
  "/category/custom_behavior" +
  (behaviorId !== undefined ? `/${behaviorId}` : "");
export const API_STUDENT_BEHAVIOR_TO_DATA_PERIOD = (
  dataPeriod: number,
  studentId: number
) => `/data_period/${dataPeriod}/evidence/behaviors/students/${studentId}`;

export const API_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD = (dataPeriod: number, behaviorTypeQueryParam: string) =>
  `/data_period/${dataPeriod}/evidences/behaviors${behaviorTypeQueryParam != '' ? behaviorTypeQueryParam : ''}`;

export const API_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD = (dataPeriod: number, behaviorTypeQueryParam: string) =>
`/data_period/${dataPeriod}/evidences/behaviors${behaviorTypeQueryParam != '' ? behaviorTypeQueryParam : ''}`;

export const API_READING_DIAGNOSTICS_ECG_TO_DATA_PERIOD = (
  dataPeriod: number
) => `/data_period/${dataPeriod}/evidences/reading_diagnostics`;

export const API_DIAGNOSTIC_INFO_COLUMN_TO_DATA_PERIOD = (dataPeriod: number) =>
  `/data_period/${dataPeriod}/evidences/diagnostic_info`;
export const API_TARGET_BEHAVIOR_TO_DATA_PERIOD = (dataPeriod: number) =>
  `/target_behavior/data_periods/${dataPeriod}`;
export const API_GAS_MEASUREMENT = "/gas_measurement/";
export const API_INTERVENTION_GROUP_SET_GOAL = (interventionGroupId: number) =>
  `/intervention_groups/${interventionGroupId}/students/goal`;
export const API_INTERVENTION_GROUP_ADD_INTERVENTIONS = (
  interventionGroupId: number
) => `/intervention_groups/${interventionGroupId}/intervention`;
export const API_INTERVENTION_GROUP_DISCONTINUE_INTERVENTION = (
  interventionGroupId: number
) => `/intervention_groups/${interventionGroupId}/intervention/discontinue`;
export const API_INTERVENTION_GROUP_SET_GOAL_FOR_SINGLE_STUDENT = (
  interventionGroupId: number,
  studentId: number,
  goalId?: number
) =>
  `/intervention_groups/${interventionGroupId}/students/${studentId}/goal${
    goalId ? `/${goalId}` : ""
  }`;
export const API_INTERVENTION_GROUP_DETAILS = (interventionGroupId: number) =>
  `/intervention_groups/${interventionGroupId}/details`;

export const API_INTERVENTION_GROUP_PRINT = (
  interventionGroupId: number,
  obj?: any
) => {
  const queryString = _.chain(obj)
    .toPairs()
    .map(([name, value]) =>
      (Array.isArray(value) && value.length) ||
      typeof value === "boolean" ||
      typeof value == "string"
        ? [name, value]
        : null
    )
    .filter((group) => !!group)
    .value();

  return (
    `/intervention_groups/${interventionGroupId}/print` +
    (queryString.length
      ? `?${queryString
          .map(
            ([key, value]: any) =>
              `${key}=${Array.isArray(value) ? value.join(",") : value}`
          )
          .join("&")}`
      : "")
  );
};
export const API_SKILL_FOCUSES = "/skills";
export const API_GROUP_RECOMMENDATIONS = (dataPeriodId: number) =>
  `/data_period/${dataPeriodId}/group_recommendations`;
export const API_STUDENT_DIAGNOSTIC_INFO = (
  dataPeriodId: number,
  studentId: number
) =>
  `/data_period/${dataPeriodId}/evidence/diagnostic_info/students/${studentId}`;
export const API_STUDENT_DSE_GRADE = (
  dataPeriodId: number,
  studentId: number,
  queryStr?: string
) => `/data_period/${dataPeriodId}/students/${studentId}/grade${queryStr}`;
export const API_CONTACT_SUPPORT = "/support";
export const API_DATA_SHARING = (staff_relationship_id?: number, coach_id?:number) =>
  "/data_sharing" + (staff_relationship_id ? `/${staff_relationship_id}` : "") + (coach_id ? `?coach_id=${coach_id}`: "");
export const API_REQUEST_KEY = () => "/data_sharing/request_key";
export const API_NOTIFICATIONS = (notificationId?: number) =>
  "/notifications" + (notificationId ? `/${notificationId}` : "");
export const API_NOTIFICATION_SETTINGS = "/notifications/settings";
export const API_FBA = (fbaId?: number) => `/fba` + (fbaId ? `/${fbaId}` : "");
export const API_FBA_ABC = "/fba/observed/abc";
export const API_FBA_OBSERVATION = (observationId?: number) =>
  "/fba/observations" + (observationId ? `/${observationId}` : "");
export const API_INTERVIEW = (interviewId?: number, dataPeriodId?: number) => {
  if (dataPeriodId) {
    return "/interview?data_period=" + dataPeriodId;
  } else {
    return "/interview" + (interviewId ? `/${interviewId}` : "");
  }
};
export const API_INTERVIEW_STUDENT = (interviewStudentId?: number) =>
  "/interview-student" + (interviewStudentId ? `/${interviewStudentId}` : "");
export const API_DOCUMENT_UPLOAD = "/document/upload";

export const API_MEETINGS = (
  meetingId?: number,
  interventionGroupId?: number
) =>
  "/meeting" +
  (meetingId
    ? `/${meetingId}`
    : interventionGroupId
    ? `?intervention_id=${interventionGroupId}`
    : "");

export const API_PREP_MEETINGS = (
  prepMeetingId?: number,
  interventionGroupId?: number
) =>
  "/prep_meeting" +
  (prepMeetingId
    ? `/${prepMeetingId}`
    : interventionGroupId
    ? `?intervention_id=${interventionGroupId}`
    : "");
export const API_FEEDBACK_MEETINGS = (
  feedbackMeetingId?: number,
  interventionGroupId?: number
) =>
  "/feedback_meeting" +
  (feedbackMeetingId
    ? `/${feedbackMeetingId}`
    : interventionGroupId
    ? `?intervention_id=${interventionGroupId}`
    : "");
export const API_SUPPORT_MEETINGS = (
  supportMeetingId?: number,
  interventionGroupId?: number
) =>
  "/support_meeting" +
  (supportMeetingId
    ? `/${supportMeetingId}`
    : interventionGroupId
    ? `?intervention_id=${interventionGroupId}`
    : "");
export const API_PROGRESS_OVERVIEW = (interventionGroupId: number, studentId: number) =>
  `/intervention_groups/${interventionGroupId}/progress_overview${studentId ? '?student_id='+studentId : ''}`;
export const API_PROGRESS_TOWARDS_GOAL = (id?: number) =>
  "/student_goals/progress" + (id ? `/${id}` : "");
export const API_PROGRESS_TOWARDS_GOAL_DELETE = (id?: number) =>
  `/student_goals/progress/${id}/delete`;
export const API_LOG_INTERVENTION = (
  interventionGroupId: number,
  implCheckId?: number
) =>
  `/intervention_groups/${interventionGroupId}/implementation_checks` +
  (implCheckId ? `/${implCheckId}` : "");
export const API_CHAT_THREADS = (chatThreadId?: number) =>
  "/chat_threads" + (chatThreadId ? `/${chatThreadId}` : "");
export const API_CHAT_MESSAGES = (chatMessageId?: number, report?: boolean) =>
  "/chat_messages" +
  (chatMessageId ? `/${chatMessageId}` : "") +
  (report ? "/report" : "");
export const API_DISTRICT_ASSESSMENT = (
  districtId: number,
  assessmentId?: number
) =>
  `/district/${districtId}/assessments${
    assessmentId ? `/${assessmentId}` : ""
  }`;
export const API_DISTRICT_CHAT_REPORT = (
  chatReportId?: number,
  print?: boolean
) =>
  "/district/chat_reports" +
  (chatReportId ? `/${chatReportId}` : "") +
  (print ? "/print" : "");

export const API_BADGES_GET = () => `/badges`;

export const API_DISTRICT_SUMMARY = (
  obj: {
    district: number | undefined;
    school: number | undefined;
    teachers: number[];
    coaches: number[];
    race: number | undefined;
    grades: Grade[];
    concern_area: ConcernArea | undefined;
    start_date: string | undefined;
    end_date: string | undefined;

    display_charts?: boolean;
    displayed_sections?: string[];
  },
  print?: boolean
) => {
  const queryString = _.chain(obj)
    .toPairs()
    .map(([name, value]) =>
      (Array.isArray(value) && value.length) ||
      Number.isInteger(value) ||
      typeof value == "boolean" ||
      typeof value == "string"
        ? [name, value]
        : null
    )
    .filter((group) => !!group)
    .value();

  return (
    "/district/summary" +
    `${print ? "/print" : ""}` +
    (queryString.length
      ? `?${queryString
          .map(
            ([key, value]: any) =>
              `${key}=${Array.isArray(value) ? value.join(",") : value}`
          )
          .join("&")}`
      : "")
  );
};

export const API_STUDENTS_DATA = (datePeriod: number) =>
  `/parent/children/data_periods/${datePeriod}`;
export const API_INTERVENTION_GROUPS_BY_PARENT = "/parent/intervention_groups";

export const getCookie = (name: string): string | undefined => {
  let cookieValue: string | undefined = undefined;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }

  return cookieValue;
};

export const API_INVITE_PARENT = "parents";
export const API_ADD_STUDENT_TO_PARENT = "parent/relationships";
export const API_ASSESSMENT_SUGGESTION = "assessments/suggestions";

export const API_SPELLING_TEST_GROUPS = (groupId?: number) =>
  `/spelling_test/groups${groupId ? `/${groupId}` : ""}`;

export const API_SPELLING_TEST_GROUPS_ADD_STUDENT = (groupId: number) =>
  `/spelling_test/groups/${groupId}/add_student`;
export const API_SPELLING_TEST_GROUPS_REMOVE_STUDENT = (groupId: number) =>
  `/spelling_test/groups/${groupId}/remove_student`;

export const API_SPELLING_TESTS = (testId?: number) =>
  `/spelling_test${testId ? `/${testId}` : ""}`;

export const API_SPELLING_TEST_QUESTIONS = (grade: Grade) =>
  `/spelling_test/test/${grade}`;

export const API_SPELLING_TEST_GENERATE_DIAGNOSTIC = (testId: number) =>
  `/spelling_test/${testId}/generate_diagnostics `;

export const API_STUDENTS_BY_GRADE = (grade: Grade) =>
  `/rosters/students?grade=${grade}`;

export const API_LESSON_NOTES = (groupId: number, lessonNotesId?: number) =>
  `/intervention_groups/${groupId}/lesson_notes` +
  (lessonNotesId ? `/${lessonNotesId}` : "");

export const API_ATTENDANCE = (groupId: number, attendanceId?: number, studentId?: number) =>
  `/intervention_groups/${groupId}/attendance` +
  (attendanceId ? `/${attendanceId}` : "") + (studentId ? `?student_id=${studentId}` : "");

export const API_GET_LATEST_RELEASE = `/get_latest_release`;

export const API_STUDENTS_BY_INTERVENTION_GROUP = "/students_by_intervention_group";
export const API_DATA_PERIOD_SCHOOLS = (dataPeriodId?: number) =>
  "/data-period-schools" + (dataPeriodId !== undefined ? `/${dataPeriodId}` : "");
export const API_RACE_INFO = (dataPeriodId?: number) =>
  "/get_race_info" + (dataPeriodId !== undefined ? `/${dataPeriodId}` : "/0");
export const API_ADMIN_MASTER_DATA = (masterDataId?: number) => 
  "/admin/master_values" + (masterDataId !== undefined ? `/${masterDataId}` : "");
export const API_ADMIN_MASTER_DATA_TYPES = () => "/admin/master_values/list_types";
export const API_ADMIN_MASTER_DATA_DISPLAY = (types?: string) =>
  "/admin/master_values/list_display_names" + (types !== undefined ? `?type=${types}` : "");
export const API_ADMIN_MASTER_DATA_VARIATIONS = (targetValues?: string) =>
  "/admin/master_values/list_variations" + (targetValues !== undefined ? `?target_value=${targetValues}` : "");
