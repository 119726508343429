import React, { Component, FunctionComponent, useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import { ValueType } from "react-select/src/types";
import { Button, Form, Spinner } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { hideGeneratePDFModal } from "../../../../../../../store/onboarding/actions";
import { IS_READY_COACH } from "../../../../../../../constants";
import { ApplicationState } from "../../../../../../../store";
import { InterventionGroup, StudentGoal } from "../../../../../../../store/onboarding/cases/types";
import { Student } from "../../../../../../../store/onboarding/types";
import { getFullName } from "../../../../../../../utils/NamesUtils";
import { changeInterventionPlanFilters } from "../../../../../../../store/onboarding/cases/actions";
import { useLoading } from "../../../../../../../utils/hooks/useLoading";

type OwnProps = {
 onHandleSubmit: () => any;
 pdfTables: any;
 showGenerateModal: boolean;
};

type PropsFromState = {
  selectedInterventionGroup?: InterventionGroup;
};

type DispatchProps = {
  hideGeneratePDFModal: () => any;
};

type Props = OwnProps & PropsFromState & DispatchProps;

const GeneratePDFModal: FunctionComponent<Props> = ({
  pdfTables,
  showGenerateModal,
  selectedInterventionGroup,
  hideGeneratePDFModal,
  onHandleSubmit
}) => {
  const dispatch = useDispatch();

  const [selectedStudentPDF, setSelectedStudentPDF] = useState<any>([]);
  const [selectedTablePDF, setSelectedTablePDF] = useState<any>(pdfTables);
  const [selectedAllStudent, setSelectedAllStudent] = useState<any>(false);

  const reportPending = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.cases.isLoading.printInterventionGroupReport
  );
  const reportError = useSelector<ApplicationState, string | undefined>(
    (s) => s.cases.errors.printInterventionGroupReport
  );
  useLoading(reportPending, reportError);

  const {
    selectedStudentForWorkSpacePDF,
    selectedPDFForWorkSpacePDF
  } = useSelector(
    (s: ApplicationState) => s.cases.modalsState.interventionPlan
  );

  const uniqueByKey = (array:Student[], key:any ='id') => {
    return Array.from(new Map(array.map((x:any) => [x[key], x])).values());
  }
  
  const studentsByInterventionGroup = useMemo(() => {
    if(selectedInterventionGroup?.students.length && !selectedInterventionGroup.archived){
      let allStudents = [
        ...selectedInterventionGroup?.student_goals.map((sg: StudentGoal) => sg.student),
        ...selectedInterventionGroup?.students
      ];
      let students = uniqueByKey(allStudents, 'id');
      if(students.length == 1) {
        setSelectedStudentPDF([...students])
        dispatch(changeInterventionPlanFilters({ selectedStudentForWorkSpacePDF: students.length ?  [...students] : [] }));
      }
      return students;
    } else {
      let studentArray: Student[] | undefined = 
          selectedInterventionGroup?.student_goals
          .filter((sg) => sg.archived)
          .map((sg) => sg.student);
      let uniqueStudentArray = uniqueByKey(studentArray!, 'id');
      if(selectedInterventionGroup?.students.length && selectedInterventionGroup.archived) {
        //when group is archive then merge active student with archive goal student
        selectedInterventionGroup?.students.forEach((intGrpStd) => {
          if(!uniqueStudentArray?.some((std) => std.id == intGrpStd.id)) {
            uniqueStudentArray?.push(intGrpStd)
          }
        })
      }
      if(uniqueStudentArray!.length == 1) {
        setSelectedStudentPDF([...uniqueStudentArray!])
        dispatch(changeInterventionPlanFilters({ selectedStudentForWorkSpacePDF: uniqueStudentArray.length ? [...uniqueStudentArray] : [] }));

      } 
      return uniqueStudentArray!;
    }
  },[selectedInterventionGroup])

  
  useEffect(()=> {
    if(selectedStudentForWorkSpacePDF){
      setSelectedStudentPDF(selectedStudentPDF)
    }
    if(selectedPDFForWorkSpacePDF){
      setSelectedTablePDF(selectedTablePDF)
    }
  },[selectedStudentForWorkSpacePDF, selectedPDFForWorkSpacePDF])


  const customStyles: any = useMemo(
    () => ({
      container: (base: any) => ({
        ...base,
        minWidth: 200,
      }),
      control: (base: any) => ({
        ...base,
        minHeight: 30,
      }),
      dropdownIndicator: (base: any) => ({
        ...base,
        padding: 4,
      }),
      clearIndicator: (base: any) => ({
        ...base,
        padding: 4,
      }),
      valueContainer: (base: any) => ({
        ...base,
        padding: "0px 6px",
      }),
      input: (base: any) => ({
        ...base,
        margin: 0,
        padding: 0,
      }),
    }),
    []
  );

  const handleSelectedStudentChange = (value: any) => {
    //let value = [{...valueObj}];
    if(value) {
      setSelectedStudentPDF(value);
      dispatch(changeInterventionPlanFilters({ selectedStudentForWorkSpacePDF: value ?? [] }));
    } else {
      setSelectedStudentPDF([])
    }
    // if (value?.length === selectedInterventionGroup?.students?.length) {
    //  dispatch(changeInterventionPlanFilters({ selectedStudentForWorkSpacePDF: [] }));
    // } else {
    //   dispatch(
    //     changeInterventionPlanFilters({ selectedStudentForWorkSpacePDF: value ?? [] })
    //   );
    // }
  };

  const handleSelectedPDFChange = (value: any) => {
    if(value) {
      setSelectedTablePDF(value);
      dispatch(changeInterventionPlanFilters({ selectedPDFForWorkSpacePDF: value ?? [] }));
    } else {
      setSelectedTablePDF([])
    }
  };

  const handleSelectedAllStudentChange = (value: any) => {
    if(value) {
      setSelectedAllStudent(value);
      setSelectedStudentPDF([]);
      if(selectedInterventionGroup?.students.length && !selectedInterventionGroup?.archived) {
        //only active group card and exited student group card
        let allStudents = [
          ...selectedInterventionGroup?.student_goals.map((sg: StudentGoal) => sg.student),
          ...selectedInterventionGroup?.students
        ];
        let students = uniqueByKey(allStudents, 'id');
        dispatch(changeInterventionPlanFilters({ selectedStudentForWorkSpacePDF: students ?? [] }));
      } else {
        // if group has Archive
        let studentArray: Student[] | undefined = 
        selectedInterventionGroup?.student_goals
        .filter((sg) => sg.archived)
        .map((sg) => sg.student);
        let uniqueStudentArray = uniqueByKey(studentArray!, 'id');
          //when group is archive then merge active student with archive goal student
          selectedInterventionGroup?.students.forEach((intGrpStd) => {
            if(!uniqueStudentArray?.some((std) => std.id == intGrpStd.id)) {
              uniqueStudentArray?.push(intGrpStd)
            }
          })
          dispatch(changeInterventionPlanFilters({ selectedStudentForWorkSpacePDF: uniqueStudentArray ?? [] }));
      }
    } else {
      dispatch(changeInterventionPlanFilters({ selectedStudentForWorkSpacePDF: [] }));
      setSelectedAllStudent(false)
    }
  };

  const handleHideModal = () => {
    hideGeneratePDFModal();
  };

  return (
    <div>
      {showGenerateModal && <Modal
        show={showGenerateModal}
        onHide={handleHideModal}
        animation={false}
        size="lg"
        backdropClassName={"customDarkModalBackdrop in"}
      >
        <Modal.Header
          closeButton
          className={
            IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"
          }
        >
          <Modal.Title><div className="mr-2">Generate PDF for Student</div> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="alert alert-warning py-1">Graph will not be generated for the student who does not have goal progress.</div>
        <h4 className="text-left font-weight-bold mb-2"> Student{`${studentsByInterventionGroup.length > 1 ? '(s)' : ''}`}:</h4>
          {studentsByInterventionGroup.length > 1
          ?
            <div className="d-flex row py-1">
              <div className="col-12">
                <Form.Check 
                  type="checkbox"
                  className="pointer"
                  id="sortSwitch"
                  label="All of the students in this case"
                  checked={selectedAllStudent ?? false}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    handleSelectedAllStudentChange(checked);
                  }}
                />
              </div>
            </div>
          : ""
          } 

          
          <div className="d-flex row">
            <div className="col-12">
              <Select
                isMulti
                isClearable
                placeholder="Select Student(s)"
                styles={customStyles}
                value={selectedStudentPDF}
                options={studentsByInterventionGroup}
                getOptionLabel={getFullName}
                getOptionValue={({ id }: Student) => id!.toString()}
                onChange={handleSelectedStudentChange}
                isDisabled={selectedAllStudent}
              />
            </div>
          </div>
          <h4 className="text-left font-weight-bold my-2"> Table(s) to include in PDF:</h4>
          <div className="d-flex row">
            <div className="col-12">
              <Select
                isMulti
                isClearable
                placeholder="Select Tables to show in PDF"
                styles={customStyles}
                value={selectedTablePDF}
                options={pdfTables ?? []}
                onChange={handleSelectedPDFChange}
              />
            </div>
          </div>
            <div className="mt-2">
            <button className="blueBtnSm mr-2" style={{ right: "2rem" }} onClick={handleHideModal}>
              Cancel
            </button>
            <button className="blueBtnSm mr-2" style={{ right: "2rem" }} onClick={onHandleSubmit}
              disabled={!selectedStudentPDF.length && !selectedAllStudent}>
                Submit {reportPending && <Spinner animation="border" size="sm" />}
            </button>
            </div>

        </Modal.Body>
      </Modal>}
    </div>
  );
}

const mapStateToProps = ({ onboarding, cases }: ApplicationState): PropsFromState => {
  return {
    selectedInterventionGroup: cases.selectedInterventionGroup,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideGeneratePDFModal: hideGeneratePDFModal
      //updateDataSharingTableData: updateDataSharingTableData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneratePDFModal);
