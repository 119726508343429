import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const NJTSSExample = () => {
  const dibels_columns = {
    "LNF": ["LNF (LNF)", "LNF"],
    "NWF-CLS": [
        "NWF-CLS (NWF-CLS)",
        "NWF - CLS(NWF - CLS)",
        "NWF - CLS (NWF - CLS)",
        "NWF (CLS)",
        "NWF (NWF-CLS)",
        "NWF: CLS",
        "NWF - CLS",
        "NWF-CLS"
    ],
    "NWF-WRC": [
        "NWF-WRC (NWF-WRC)",
        "NWF - WRC (NWF-WRC)",
        "NWF - WRC (NWF - WRC)",
        "NWF (NWF-WRC)",
        "NWF (WRC)",
        "NWF: WRC",
        "NWF - WRC",
        "NWF-WRC"
    ],
    "ORF-Accuracy": [
        "ORF-ACC (ORF-ACC)",
        "ORF (Percent)",
        "ORF (Accuracy)",
        "ORF - Accuracy(ORF - Accuracy)",
        "ORF - Accuracy (ORF - Accuracy)",
        "ORF-Accuracy(ORF-Accuracy)",
        "ORF(ORF - Accuracy)",
        "ORF (ORF - Accuracy)",
        "ORF(ORF-Accuracy)",
        "ORF (ORF- Accuracy)",
        "ORF (ORF -Accuracy)",
        "ORF - Accuracy (ORF - Accuracy)"
    ],
    "ORF-WordsCorrect": [
        "ORF (ORF)",
        "ORF (ORF-Words Correct)",
        "ORF (ORF - Words Correct)",
        "ORF (Words Correct)",
        "ORF (ORF Words Correct)",
        "ORF - Words Correct (ORF - Words Correct)",
        "ORF-Words Correct (ORF-Words Correct)"
    ],
    "PSF": ["PSF (PSF)", "PSF"],
    "WRF": ["WRF (WRF)", "WRF"],
    "Maze-Adjusted": [
        "MAZE",
        "Maze (Maze Adjusted)",
        "MAZE (Maze Adjusted)",
        "Maze (MAZE Adjusted)",
        "MAZE (MAZE Adjusted)",
        "Maze",
        "Maze-Adj",
        "MAZE - Adj",
        "Adj",
        "Maze (Maze)",
        "Maze(Maze)",
        "Maze Adjusted",
        "Maze Adjusted (Maze Adjusted)",
        "Maze Adjusted(Maze Adjusted)"
    ],
    "Composite": [
        "Composite-Score",
        "Composite",
        "Composite - Score",
        "Composite Score"
    ]
}
  const variants = (type: string, variantArray:any) => {
    return <OverlayTrigger
      overlay={ (
          <Popover id={type}>
            <Popover.Content>
            <p className="font-weight-bold mb-1">Accepted header variation for {type}</p>
              <ul>
                {variantArray.length 
                  ? variantArray.map((element:string) => 
                      <li>{element}</li>
                    ) 
                  : ''}
              </ul>
            </Popover.Content>
          </Popover>
        )
      }
    >
      <FontAwesomeIcon
        icon={faQuestionCircle}
        size="1x"
        style={{ color: "#00265b" }}
        className="mx-1"
      />
    </OverlayTrigger>
  }
  return (
    <>
      <h4 className="font-weight-bold mb-2">Example Format</h4>
      <div className="overflow-auto mb-3">
        <Table className="uploadExampleTable table-mb">
          <thead>
            <tr>
              <th>DIBELS SCORE ENTRY</th>
              <th>Rutgers Test</th>
              <th>2021</th>
              <th>K</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th>School</th>
              <th>Teacher</th>
              <th>Student ID#</th>
              <th>Last Name</th>
              <th>First Name</th>
              <th>Gender</th>
              <th>Race</th>
              <th>Free or Reduced Lunch</th>
              <th>Special Education</th>
              <th>SLD</th>
              <th>ELL</th>
              <th>LNF {variants('LNF', dibels_columns.LNF)}</th>
              <th>PSF {variants('PSF', dibels_columns.PSF)}</th>
              <th>NWF-CLS {variants('NWF-CLS', dibels_columns["NWF-CLS"])}</th>
              <th>NWF-WRC {variants('NWF-WRC', dibels_columns["NWF-WRC"])}</th>
              <th>WRF {variants('WRF', dibels_columns.WRF)}</th>
              <th>ORF {variants('ORF', dibels_columns["ORF-WordsCorrect"])}</th>
              <th>ORF-ACC {variants('ORF-ACC', dibels_columns["ORF-Accuracy"])}</th>
              <th>MAZE {variants('MAZE', dibels_columns["Maze-Adjusted"])}</th>
              <th>Composite {variants('Composite', dibels_columns.Composite)}</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sample School</td>
              <td>Sample Teacher</td>
              <td>33333</td>
              <td>Doe</td>
              <td>John</td>
              <td>Male</td>
              <td>White</td>
              <td>Y</td>
              <td>N</td>
              <td>N</td>
              <td>N</td>
              <td>49</td>
              <td>41</td>
              <td>36</td>
              <td>11</td>
              <td>7</td>
              <td>34</td>
              <td>34</td>
              <td>2.0</td>
              <td>330</td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </div>
      <p className="mb-1">In addition to matching your file with the example format, please do not change names or the order of worksheets in the workbook, and ensure your file does <b><u>not</u></b> contain:</p>
      <ul>
        <li>Duplicate student IDs</li>
        <li>Blank student IDs</li>
        <li>Blank student names</li>
        <li>Additional worksheets</li>
      </ul> 
    </>
  );
};
export default NJTSSExample;
