import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { Card, Modal, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parseCsvFile } from "../../../../../utils/files";
import { ParseResult } from "papaparse";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import {
  hideUploadStudentDemographicModal,
  uploadStudentDemographics,
} from "../../../../../store/onboarding/actions";
import { ApplicationState } from "../../../../../store";
import RaceDemographicsExample from "../upload-student-data/RaceDemographicsExample";
import { showConfirmDialog } from "../../../../../store/confirm-dialog/actions";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import AccordianContainer from "../../../../pages/coach-dashboard/tabs/your-assigments/common/AccordianContainer";
import { getMasterDataDisplayNames, getMasterDataVariations } from "../../../../../store/superuser/actions";
import { Master_Data, SuperuserState } from "../../../../../store/superuser/types";
import LoadingIndicator from "../../LoadingIndicator";

type OwnProps = {};

type Props = OwnProps;

const COLUMN_NAMES = ["student_id", "Race"];

const UploadStudentDemographicModal: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const [openId, setOpenId] = useState<boolean>();
  const [raceVariation, setRaceVariation] = useState<any>([]);
 
  const show = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.onboarding.modalsState.showUploadStudentDemographicModal
  );
  const isLoading = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.onboarding.isLoading.uploadStudentDemographics
  );
  
  useEffect(() => {
    setOpenId(false);
    if(show) {
      dispatch<any>(getMasterDataDisplayNames('races'))
      .then((displayNameResponse: Master_Data[]) => {
        let raceVariations: any[] = [];
        if(displayNameResponse.length) {
          for (let i = 0; i < displayNameResponse.length; i++) {
            const races = displayNameResponse[i];
            dispatch<any>(getMasterDataVariations(races.target_value!))
            .then((raceTypes: any) => {
              if(raceTypes.length) {
                let raceVariationsData = raceTypes.map((raceType: any) => raceType.variation).join('; ')
                raceVariations.push({
                  displayName: races.display_name,
                  variations: raceVariationsData
                })
                setRaceVariation(raceVariations);
              }
            });
          }
        }
      })
    }
  },[show])

  const handleHide = () => {
    dispatch(hideUploadStudentDemographicModal());
  };

  const fileInputRef = useRef<HTMLInputElement>(null);


  const isValidFileUploaded=(file: File)=>{
    const validExtensions = ['csv','vnd.ms-excel'];
    const fileExtension = file.type.split('/')[1];
    return validExtensions.includes(fileExtension);
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null) {
      const file: File = event.target.files[0];
      if(file != null && !isValidFileUploaded(file)){
        toastr.error('Alert!!', 'To upload data from a file, it needs to be in CSV format and include a header row. It must include two columns, student_id and Race.')
        return;
      }
      parseCsvFile({
        file: file,
        onComplete: (results) => handleCSVData(results, file),
        onShowError: showError,
      });
      // clear the existing value
      event.target.value = "";
    }
  };

  const handleCSVData = (results: ParseResult, file: File) => {
    if (
      //results.meta.fields?.length === 2 &&
      _.intersection(results.meta.fields, COLUMN_NAMES).length
    ) {
      const invalidValue = results.data.find((v) => {
        const studentId = v[COLUMN_NAMES[0]];
        return Number.isNaN(Number(studentId));
      });
      if (invalidValue) {
        toastr.error(
          "The Student ID column contains invalid value(s):",
          invalidValue[COLUMN_NAMES[0]]
        );
      } else {
        dispatch<any>(uploadStudentDemographics(file)).then(
          (updatedRecords: number) => {
            toastr.success(
              "Success",
              updatedRecords > 0 ? `Demographics is updated successfully for ${updatedRecords} student${updatedRecords > 1 ? '(s)' : ''}` 
              : ''+updatedRecords
            );
            handleHide();
          },
          (error: string) => {
            toastr.error("Error", error);
          }
        );
      }
    } else {
      toastr.error(
        "The file is not valid:",
        `The file must have ${COLUMN_NAMES.length} columns ${COLUMN_NAMES.join(
          ", "
        )}`
      );
    }
  };

  const showError = () => {
    toastr.error("Unable to upload a file:", "This file type is not supported");
  };

  const handleFileInputModal = () => {
    dispatch(showConfirmDialog({
      onConfirm: () => fileInputRef.current?.click(),
      centered: true,
      title: "Confirmation",
      timerElipseLeft: true,
      countDownTimer: 5,
      text: (
        <><FontAwesomeIcon 
        icon={faExclamationTriangle}
        style={{color:'red'}}
      /> {" "}
           Before uploading your file, it is extremely important to review your file with the guidance outlined in this modal. 
           If your file structure is not in <span className="font-weight-bold">exact</span> alignment (e.g., column names, casing, spaces, etc),
           it may prevent you from uploading your file or result in an incomplete upload of your file.
        </>
      ),
      confirmButtonText: "Confirm"
    }))
  }

  return (
    <Modal
      show={show}
      onHide={handleHide}
      animation={false}
      size="lg"
      backdropClassName="customDarkModalBackdrop in"
    >
      <Modal.Header closeButton className="purpleModalHeader">
        <Modal.Title>Upload Student Demographics CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2 className="text-center font-weight-semibold mb-xl-3 mb-2">
          We can guide you in uploading data.
        </h2>
        <p className="text-center mb-2 font-weight-bold">
          To upload data from a file, it needs to be in CSV format and include a
          header row. It must include two columns, student_id and
          Race.
        </p>
        <p className="text-center mb-2 font-weight-bold">
          To upload student demographics, columns in the file must be named 
          exactly as shown in the example below and except for Race and Gender entries, 
          contain a Y for yes. If the demographic does not apply to the student, 
          leave the cell empty. To view the accepted Race/Ethnicity and Gender name 
          variations, please refer to the Race/Ethnicity and Gender Demographics section below.  
        </p>
        <div className="overflow-auto">
          <h4 className="font-weight-bold mb-2">Example Format</h4>
          <Table className="uploadExampleTable">
            <thead>
              <tr>
                <th>student_id</th>
                <th>Race</th>
                <th>Special Education</th>
                <th>ELL</th>
                <th>SLD</th>
                <th>Gender</th>
                <th>Free or Reduced Lunch</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1001</td>
                <td>White</td>
                <td>Y</td>
                <td></td>
                <td>Y</td>
                <td>Male</td>
                <td>Y</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <Card>
          <Card.Header>
            <FontAwesomeIcon icon="info-circle" /> Why do we need Student ID?
          </Card.Header>
          <Card.Body>
            <Card.Text className="smallerText">
              <>
                The Student ID should be unique for each student across in all
                files uploaded to ReadyCoach. This lets us match up student data
                without relying on their names.
              </>
            </Card.Text>
          </Card.Body>
        </Card>
        <AccordianContainer 
          className="demographicsAccordion my-3"
          label="Race/Ethnicity and Gender Demographics Variations"
          open={openId}
          onClick={() => setOpenId(openId => !openId)}
        >
          {openId && !raceVariation.length ? <LoadingIndicator/> : '' }
          {openId && raceVariation.length ? <RaceDemographicsExample raceVariations={raceVariation}/> : ''}
        </AccordianContainer>
        <h5 className="text-center font-weight-normal m-3">
          Click the button below to choose a student data CSV file from your
          computer to upload
        </h5>
        <div className="text-center">
          <button
            className="blueBtnXL"
            data-cy="upload-data-csv-file"
            onClick={handleFileInputModal}
          >
            Upload the Student Data CSV File{" "}
            {isLoading && <Spinner animation="border" size="sm" />}
          </button>
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
            accept={".csv, text/csv, .xlsx"}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadStudentDemographicModal;
